<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <form
    ref="contactForm"
    autocomplete="off"
    @submit.prevent="onSubmitInternal"
  >
    <div v-if="Object.keys(errors).length > 0" class="mt-2 mb-6 text-center font-medium text-red-600">
      <p>
        Please fill in the required fields below
      </p>
    </div>
    <div class="highlight flex space-x-4">
      <FormItem
        :field="honeyPot1"
        label="Policy number insured 1"
        :required="true"
        class="flex-1"
        for="contactUs-policy_number_insured_1"
        error-id="contactUs-policy_number_insured_1-error"
        tabindex="-1"
      >
        <Input
          id="contactUs-policy_number_insured_1"
          v-model="honeyPot1.value.value"
          :errors="honeyPot1.errors.value"
          :disabled="loading"
          :no-error-class="sentEmailClass"
          aria-label="Policy number insured 1"
          aria-describedby="contactUs-policy_number_insured_1-error"
          tabindex="-1"
          @ref="honeyPot1.setRef"
        />
      </FormItem>
      <FormItem
        :field="honeyPot2"
        label="Policy number insured 2"
        :required="true"
        class="flex-1"
        for="contactUs-policy_number_insured_2"
        error-id="contactUs-policy_number_insured_2-error"
        tabindex="-1"
      >
        <Input
          id="contactUs-policy_number_insured_2"
          v-model="honeyPot2.value.value"
          :errors="honeyPot2.errors.value"
          :disabled="loading"
          :no-error-class="sentEmailClass"
          aria-label="Policy number insured 2"
          aria-describedby="contactUs-policy_number_insured_2-error"
          tabindex="-1"
          @ref="honeyPot2.setRef"
        />
      </FormItem>
    </div>
    <div class="sm:flex sm:space-x-5">
      <FormItem
        :field="fullName"
        label="Full name"
        :required="true"
        class="flex-1"
        for="contactUs-fullName"
        error-id="contactUs-fullName-error"
      >
        <Input
          id="contactUs-fullName"
          v-model="fullName.value.value"
          class="border-gray-500"
          :errors="fullName.errors.value"
          :disabled="loading"
          :no-error-class="sentEmailClass"
          :required="true"
          aria-label="Full name"
          aria-describedby="contactUs-fullName-error"
          autocomplete="name"
          @ref="fullName.setRef"
        />
      </FormItem>
      <FormItem
        :field="school"
        label="School name"
        :required="true"
        class="flex-1"
        error-id="contactUs-school-error"
      >
        <Input
          id="contactUs-school"
          v-model="school.value.value"
          class="border-gray-500"
          :errors="school.errors.value"
          :disabled="loading"
          :no-error-class="sentEmailClass"
          :required="true"
          aria-label="School name"
          aria-describedby="contactUs-school-error"
          @ref="school.setRef"
        />
      </FormItem>
    </div>
    <div class="sm:flex sm:space-x-5">
      <FormItem
        :field="email"
        label="Email"
        :required="true"
        class="flex-1"
        error-id="contactUs-email-error"
      >
        <Input
          id="contactUs-email"
          v-model="email.value.value"
          class="border-gray-500"
          :errors="email.errors.value"
          type="email"
          :disabled="loading"
          :no-error-class="sentEmailClass"
          aria-label="Email"
          :required="true"
          aria-describedby="contactUs-email-error"
          autocomplete="email"
          @ref="email.setRef"
        />
      </FormItem>
      <FormItem
        :field="phone"
        label="Phone"
        class="flex-1"
      >
        <Input
          v-model="phone.value.value"
          v-maska
          class="border-gray-500"
          :errors="phone.errors.value"
          data-maska="(###) ###-####"
          :disabled="loading"
          :no-error-class="sentEmailClass"
          aria-label="Phone"
          autocomplete="tel"
          @ref="phone.setRef"
        />
      </FormItem>
    </div>
    <div class="sm:flex sm:space-x-5">
      <FormItem :field="product" label="Which product is this about?" class="flex-1">
        <Select
          v-model="product.value.value"
          class="border-gray-500"
          :errors="product.errors.value"
          :disabled="loading"
          :no-error-class="sentEmailClass"
          @ref="product.setRef"
        >
          <option selected value="" disabled>
            Select
          </option>
          <option value="Renters">
            Renters Insurance
          </option>
          <option value="Tuition">
            Tuition Insurance
          </option>
          <option value="Other">
            Something Else
          </option>
        </Select>
      </FormItem>
      <FormItem :field="question" label="What can we help you with?" class="flex-1">
        <Select
          v-model="question.value.value"
          class="border-gray-500"
          :errors="question.errors.value"
          :disabled="loading"
          :no-error-class="sentEmailClass"
          @ref="question.setRef"
        >
          <option selected value="" disabled>
            Select
          </option>
          <option value="General Inquiry">
            Purchase
          </option>
          <option value="General Inquiry">
            Questions About Coverage
          </option>
          <option value="Claim">
            New or Existing Claim
          </option>
          <option value="Billing">
            Questions About My Bill
          </option>
          <option value="Cancel a Policy">
            Cancellation
          </option>
          <option value="General Inquiry">
            Something Else Important
          </option>
        </Select>
      </FormItem>
    </div>
    <div v-if="question.value.value === 'Cancel a Policy'" class="sm:flex sm:space-x-5 mb-5">
      <div class="w-full p-6 text-primary-lighter border-2 border-primary-lighter rounded-lg">
        <p v-if="product.value.value === 'Renters'">
          You may also view, make changes or cancel
          your existing policy at
          <a
            href="https://account.gradguard.com"
            target="_blank"
            rel="noopener noreferrer"
            class="underline"
          >account.gradguard.com</a>
          or by contacting our Agency Team at
          <a href="tel:8669857598" class="underline">(866) 985-7598</a>.
        </p>
        <p v-else-if="product.value.value === 'Tuition'">
          You may make changes to or cancel your existing
          plan by contacting our Agency Team
          at <a
            href="mailto:memberservices@gradguard.com"
            target="_blank"
            rel="noopener noreferrer"
            class="underline"
          >memberservices@gradguard.com</a>
          or <a href="tel:8669857598" class="underline">(866) 985-7598</a>.
        </p>
        <p v-else>
          You may also view, make changes or cancel your existing plan or policy at <a
            href="https://account.gradguard.com"
            target="_blank"
            rel="noopener noreferrer"
            class="underline"
          >
            account.gradguard.com
          </a> or by contacting our Agency Team at <a
            href="tel:8669857598"
            class="underline"
          >(866) 985-7598</a>.
        </p>
      </div>
    </div>
    <FormItem :field="message" label="Your message" :required="true" error-id="contactUs-yourMessage-error">
      <TextArea
        id="contactUs-yourMessage"
        v-model="message.value.value"
        :errors="message.errors.value"
        :disabled="loading"
        class="h-28 border-gray-500"
        :no-error-class="sentEmailClass"
        minlength="1"
        maxlength="65535"
        :required="true"
        aria-describedby="contactUs-yourMessage-error"
        @ref="message.setRef"
      />
    </FormItem>
    <div v-if="isEmailSent && hideSubmit">
      <div class="mb-5 text-center">
        <CheckIcon class="w-10 p-1 mx-auto bg-positive rounded-full text-white" />
      </div>
      <Alert
        type="success"
        :hide-icon="true"
        class="mb-5"
        aria-live="assertive"
      >
        We appreciate you contacting GradGuard.
        One of our agents will be in touch with you soon.
        Have a great day!
      </Alert>
    </div>
    <Errors v-if="error" :error="error" />
    <div class="mt-8 text-center">
      <Button
        v-if="!hideSubmit"
        :loading="loading"
        :disabled="loading"
        text="Submit"
        text-loading="Submitting"
        type="primary"
        html-type="submit"
      />
      <ButtonAsLink
        v-else
        text="Submit another"
        @click="onSubmitAnother"
      />
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import * as Yup from 'yup';
import { func, object, oneOfType } from 'vue-types';
import { CheckIcon } from '@heroicons/vue/24/outline';
import { vMaska } from 'maska/vue';
import Bugsnag from '@bugsnag/js';

import Alert from '../../ui/Alert/Alert.vue';
import Errors from '../errors/errors.vue';
import { FormValues } from './ContactFormValues';
import Button from '@/ui/Button/Button.vue';
import {
  FormItem, Input, Select, TextArea,
} from '@/ui/Form';
import { ServerErrors } from '@/plugin/ajax/ApiResponse';
import useForm from '@/plugin/form/useForm';
import ButtonAsLink from '@/ui/Button/ButtonAsLink.vue';

export default defineComponent({
  name: 'ContactForm',
  components: {
    FormItem,
    Input,
    Select,
    TextArea,
    Button,
    Errors,
    Alert,
    CheckIcon,
    ButtonAsLink,
  },
  directives: { maska: vMaska },
  props: {
    loading: Boolean,
    error: oneOfType<ServerErrors | null>([]),
    initialValues: object<FormValues>(),
    isEmailSent: Boolean,
    onSubmit: func<(values: FormValues) => Promise<boolean>>().isRequired,
  },
  setup(props) {
    const hideSubmit = ref(false);
    const setHideSubmit = (value: boolean) => { hideSubmit.value = value; };
    const {
      handleSubmit, resetForm, register, focusError, errors,
    } = useForm({ initialValues: props.initialValues });

    const fullName = register('fullname_contact', Yup.string().trim().required('Please enter a full name'));
    const school = register('school_name', Yup.string().trim().required('Please enter a school name'));
    const email = register('email_contact', Yup.string().email().trim().required('Please enter an email'));
    const phone = register('phone_contact', Yup.string());
    const product = register('product');
    const question = register('question_type', Yup.string());
    const message = register('message', Yup.string().trim().required('Please enter a message'));
    const honeyPot1 = register('policy_number_insured_1');
    const honeyPot2 = register('policy_number_insured_2');

    const onSubmitInternal = handleSubmit(
      async (values: FormValues) => {
        if (values.policy_number_insured_1 || values.policy_number_insured_2) {
          Bugsnag.addOnError((event) => event.addMetadata('form_values', values));
          throw new Error('Something went wrong');
        }
        const itWorks = await props.onSubmit(values);
        if (itWorks) {
          setHideSubmit(true);
        }
      },
      focusError,
    );

    const sentEmailClass = computed(() => {
      if (props.isEmailSent && hideSubmit.value) {
        return 'bg-green-200';
      }
      return '';
    });

    const onSubmitAnother = () => {
      resetForm();
      setHideSubmit(false);
    };

    return {
      onSubmitInternal,
      resetForm,
      fullName,
      school,
      email,
      phone,
      product,
      question,
      message,
      honeyPot1,
      honeyPot2,
      hideSubmit,
      setHideSubmit,
      sentEmailClass,
      onSubmitAnother,
      errors,
    };
  },

  methods: {
    today: () => (new Date()).toISOString().split('T')[0],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onInvalidSubmit(values: any) {
      const field = Object.keys(values.errors)[0];
      document.getElementById(field)?.focus();
    },
  },

});
</script>
