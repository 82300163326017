<script setup lang="ts">
import InifitySlider from '@/ui/InfinitySlider/InfinitySlider.vue';

const images = [
  {
    id: '/images/news-logos/bloomberg.svg',
    alt: 'Bloomberg',
  },
  {
    id: '/images/news-logos/cbs-news.svg',
    alt: 'CBS News',
  },
  {
    id: '/images/news-logos/cnbc.svg',
    alt: 'CNBC',
  },
  {
    id: '/images/news-logos/forbes.svg',
    alt: 'Forbes',
  },
  {
    id: '/images/news-logos/fox-news.svg',
    alt: 'Fox News',
  },
  {
    id: '/images/news-logos/inside.svg',
    alt: 'Inside',
  },
  {
    id: '/images/news-logos/nyt.svg',
    alt: 'NYT',
  },
  {
    id: '/images/news-logos/squawk-box.svg',
    alt: 'Squawk Box',
  },
  {
    id: '/images/news-logos/wsj.svg',
    alt: 'The Wall Street Journal',
  },
  {
    id: '/images/news-logos/us-news.svg',
    alt: 'US News',
  },
];
</script>

<template>
  <InifitySlider
    :items="images"
    class="max-w-screen-2xl w-[1024px] mx-auto"
  >
    <template #default="{ item }">
      <div class="px-4">
        <img
          :src="item.id"
          :alt="item.alt"
          class="max-w-none"
        />
      </div>
    </template>
  </InifitySlider>
</template>
